import React from 'react'
//require('typeface-clear-sans')
//import "fontsource-clear-sans"
//import logo from '../../logo.svg';
import {
    BrowserRouter as Router,
    useNavigate
  } from "react-router-dom";

function About() {

  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#ffffff"}}>
            <a className="navbar-brand" href="/">
                <img src={require('../../logoGreen.png')} className="border border-white rounded" width={40} height={40} />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                <li className="nav-item active">
                    <a className="nav-link" href="">About <span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="../privacy">Privacy</a>
                </li>
                </ul>
            </div>
        </nav>
        <div className="container-fluid text-white" style={{backgroundColor: '#4AE48E'}}>
        <div className="col-sm-12">
            <br />
            <h1 className="text-center">About us</h1>
            <br />
            <div className="container">
              <div className="row">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                      <p className="text-center">Maths Builders was created by Richard Prager and Russell Hunter. The design is by Max Murdoch. The content was created and developed by Russell Hunter, Eszter Olah and Yi Wang. </p>
                  </div>
                  <div className="col-sm-2"></div>
              </div>
              <br />
              <div className="row">
                  <div className="col-sm-5">
                      <h3>Prof. Richard Prager</h3>
                      <h5>Project Leader</h5>
                      <img src={require("../../richard-prager.jpeg")} className="border border-white rounded" width="100%" />
                  </div>
                  <div className="col-sm-2">
                    <br />
                  </div>
                  <div className="col-sm-5">
                      <h3>Dr Russell Hunter</h3>
                      <h5>Senior Research Associate</h5>
                      <img src={require("../../russell_hunter.jpeg")} className="border border-white rounded" width="100%" />
                  </div>
              </div>
              <br />
              <br />
              <div className="row">
                <div className="col-sm-10">
                  <h3>Support</h3>
                  <br />
                  <p>If you require support for the Maths Builders application, contact Russell at:</p>
                  <p>rih28@cam.ac.uk</p>
                </div>
              </div>
              <br />
            </div>
        </div>
    </div>
    </div>
  );
}

export default About;