import React, { useEffect, useState, useContext } from 'react'
import lg from '../../iwtse-icon.svg'
import ph from '../../phone.svg'
import mb from '../../mb_mob_giph.gif'
import ap from "../../Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
import { ReactSVG } from 'react-svg'

function Home({ user }) {

  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: '#ffffff'}}>
        <a className="navbar-brand" href="">
            <img src={require('../../logoiOS.png')} className="border border-white rounded" width="40" height="40" />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
            <li className="nav-item">
                <a className="nav-link" href="/about">About</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="/privacy">Privacy</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#learn">Learn more</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#example">Example</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" href="#showme">Show me</a>
            </li>
        <li className="nav-item">      
            <a className="nav-link" href="https://i-want-to-study-engineering.org"><ReactSVG beforeInjection={(svg) => {
                        svg.classList.add('svg-class-name')
                        svg.setAttribute('style', 'height: 30px; width: 30px;')
                     }} src={lg} /></a>
            </li></ul>
        </div>
        </nav>
        <div className="container-fluid h-100" style={{backgroundColor: '#5EC8F2', minHeight: '100%'}}>
            <div className="container h-100">
                <div className="row text-center h-100 justify-content-center align-items-center">
                <div className="col-sm-4" style={{minWidth: '320px'}}>
                    <div style={{width: '100%'}} className="row h-100 justify-content-center align-items-center">
                    <ReactSVG beforeInjection={(svg) => {
                     }} src={ph} />
                        <img src={mb} style={{width: '262px', position: 'absolute', top: '134px'}} alt="animate" />
                    </div>
                </div>
                <div className="col-sm-4" style={{minWidth: '320px'}}>
                    <h3 className="display-4">Maths Builders</h3>
                    <p className="lead">Learn to solve GCSE maths problems in a fun way.
    Lots of sites let you practice arithmetic.
    Maths Builders lets you practice the problem solving part;
    and you can use it on the move.</p>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <a href="https://itunes.apple.com/us/app/maths-builders/id1390997498?ls=1&amp;mt=8">
                        <ReactSVG beforeInjection={(svg) => {
                        }} src={ap} />
                    </a>
                        <a href="https://play.google.com/store/apps/details?id=uk.ac.cam.eng.rih053.mathsbuilders&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"><img alt="Get it on Google Play" style={{height: '57px'}} src="https://play.google.com/intl/en_gb/badges/images/generic/en_badge_web_generic.png" /></a>
                    </div>
                    <br />
                    <br />
                </div>
                </div>
                <br />
                <br />
            </div>
        </div>
        <div className="container-fluid h-100" style={{backgroundColor: '#4AE48E'}}>
            <div className="container h-100">
                <br />
                <div className="row text-center h-100 justify-content-center align-items-center">
                <div className="col-sm-12">
                    <h2 style={{color: '#232323'}} id="learn">How can Maths Builders help me?</h2>
                </div>
                <div className="col-sm-12 youtube-responsive-container" >
                    <iframe src="https://www.youtube.com/embed/owcgMbFeKX0" style={{ width: '60vw', height: '30vw' }} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                </div>
                </div>
                <br />
                <br />
            </div>
        </div>
        <div className="container-fluid h-100" style={{backgroundColor: '#FFFFFF'}}>
            <div className="container h-100">
                <br />
                <div className="row text-center h-100 justify-content-center align-items-center">
                <div className="col-sm-12">
                    <h2 style={{color: "#232323"}} id="example">How do I use Maths Builders?</h2>
                </div>
                <div className="col-sm-12 youtube-responsive-container">
                    <iframe src="https://www.youtube.com/embed/gSh_kVT4r1Q" style={{ width: '60vw', height: '30vw' }} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                </div>
                </div>
                <br />
            </div>
        </div>
        <div className="container-fluid h-100" style={{backgroundColor: "#000000"}}>
            <div className="container h-100">
                <br />
                <div className="row text-center h-100 justify-content-center align-items-center">
                <div className="col-sm-12">
                    <h2 style={{color: "#dedede"}} id="showme">Let's go through a question</h2>
                </div>
                <div className="col-sm-12 youtube-responsive-container">
                    <iframe src="https://www.youtube.com/embed/-eYd98li62M" style={{ width: '60vw', height: '30vw' }} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true}></iframe>
                </div>
                </div>
                <br />
            </div>
        </div>
    </div>
  );
}

export default Home;