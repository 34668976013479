import React from 'react'

import {
    BrowserRouter as Router,
    useNavigate
  } from "react-router-dom";
import { ReactSVG } from 'react-svg'

function Privacy({ user }) {

  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#ffffff"}}>
          <a className="navbar-brand" href="/">
            <img src={require("../../logoRed.png")} className="border border-white rounded" width="40" height="40" />
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="../about">About</a>
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="">Privacy <span className="sr-only">(current)</span></a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="container-fluid" style={{backgroundColor: '#FD686A'}}>
      <div className="container">
        <div className="col-sm-12">
            <br />
            <h1 className="text-center">Privacy Policy</h1>
            <br />
            <h2>General</h2>
            <br />
            <p>
                In this Policy the “App” means the Maths Builders App operated by the maths.builders project at the 
                Cambridge University Engineering Department (the “Project ” or “we ”). The term “you ” refers to the 
                user or viewer of the App. By using the App, you consent to the data practices described in this policy. 
                This Policy sets out the basis on which any personal data we collect from you, or that you provide to us, 
                will be processed by us. Any changes to this Privacy Policy will be posted on this page.
            </p>
            <br />
            <h2>Data Collected</h2>
            <br />
            <p>
                The App does not gather any personal data about you. The only data collected by the App is:
            </p>
            <ul>
                <li>Your current score as an anonymous user.</li>
                <li>Your current best streak as an anonymous user.</li>
            </ul>
            <p>
                In the following circumstances, we may collect and process the following data about you:
            </p>
            <ul>
              <li>If you communicate with us by phone, email or otherwise, we may keep a record of that correspondence.</li>
            </ul>
            <br />

            <h2>Security measures and potential international transfer of personal information</h2>
            <br />
            <p>The data we collect from you may be transferred and stored in a country or countries outside the European Economic Area (EEA). It may also be processed by our staff operating outside the EEA or by one of our contractors or others we appoint to assist us in hosting or operating certain features of this Website. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.</p>
            
            <br />
            <h2>Disclosure and uses of personal information</h2>
            <br />
            <p>We may disclose your personal information to third parties in the following circumstances:</p>
            <ul>
              <li>If we have obtained your consent to do so</li>
              <li>When we are required or authorised by law, court order, regulatory or governmental authority to disclose your personal data</li>
              <li>If required to protect the rights, property or safety of the Project, the University of Cambridge, their members or others.</li>
            </ul>
            <br />
            <h2>Links to other websites</h2>
            <br />
            <p>The App contains links to third party sites. This Privacy Policy does not cover the use of your personal data on any third party website. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>
            <br />
            <h2>Access to personal information</h2>
            <br />
            <p>For the purpose of the Data Protection Act 1998, the ‘Data Controller’ for the processing of data collected by this Website is the University of Cambridge, and the point of subject access requests is the University Data Protection Officer (The Old Schools, Trinity Lane, Cambridge CB2 1TN, tel. 01223 332320, fax 01223 332332, E-mail data.protection@admin.cam.ac.uk .</p>
        </div>
      </div>
      <br />
    </div>
    </div>
  );
}

export default Privacy;