import './App.css';
import Home from './components/Home/Home'
import About from './components/About/About'
import Privacy from './components/Privacy/Privacy'

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router forceRefresh={true}>
      <Routes>
        <Route path='*' element={<Home />} />
        <Route exact path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/privacy" element={<Privacy />}/>
      </Routes>
    </Router>
  );
}

export default App;
